import forEach from "for-each"
import Cookie from "js-cookie"
import pJSON from "../../../package.json"
import { EventsListener } from "../common/eventListener"
import { Conditions } from "./conditions"

import { XHRTransport } from "./transports/xhr"

import { MouseMoveHandler } from "./handlers/mouseMove"
import { MouseUpHandler } from "./handlers/mouseUp"
import { MouseDownHandler } from "./handlers/mouseDown"
import { MouseClickHandler } from "./handlers/mouseClick"
import { MouseDoubleClickHandler } from "./handlers/mouseDblClick"
import { WindowResizeHandler } from "./handlers/windowResize"
import { WindowScrollHandler } from "./handlers/windowScroll"
import { DOMMutationHandler } from "./handlers/domMutation"
import { LocationChangeHandler } from "./handlers/locationChange"
import { SelectionHandler } from "./handlers/selection"
import { ScrollHandler } from "./handlers/scroll"
import { WindowBlurHandler } from "./handlers/windowBlur"
import { WindowFocusHandler } from "./handlers/windowFocus"

import { Utils } from "./utils/utils"
import { debug, log, enableLogSentry } from "../common/tools"
import { EventsStream, IEventsStream } from "./events/stream"
import { EventsBundler } from "./bundler"
import { Activity, IActivity } from "./activity"
import { Visitor } from "./visitor"
import { Account } from "./account"
import { PageView } from "./pageView"
import { Handlers } from "./handlers"
import { events, consoleLogLevel, defaultConditionEvents } from "./events/typedef"
import { CSSChangeHandler } from "./handlers/cssChange"
import { Options } from "./options"

import { Hooks } from "./hooks"

import { InputWatcher } from "./inputWatcher"
import { FormWatcher } from "./formWatcher"
import { TouchMove } from "./handlers/touchMove"

import { Integrations } from "./integrations/integrations"
import { Intercom } from "./integrations/intercom"
import { Console } from "./handlers/console"
import { mapEventProps, maxEventNameCleanup } from "./events/customEvents"
import { LiveChat } from "./integrations/livechat"
import { PerformanceHandler } from "./handlers/performanceHandler"
import { TechnologyScanHandler } from "./handlers/technologyScan"
import { isObject } from "./events/customEventsHelper"
import { map } from "../helpers/objectHelper"
import { Network } from "./handlers/network"
import { IOptions } from "./options"
import { IUtils } from "./utils/utils"
import { AnimationsHandler } from "./handlers/animations"

const ctxLog = (...args) => {
    debug("Recorder", ...args)
}

interface IScriptStatus {
    trackId: string
    trackCodeValid: boolean
    recordingEnabledValid: boolean
    error_message?: string
}
interface IRecorder {
    isSessionInited: boolean
    timeoutID: number
}
export class Recorder implements IRecorder {
    options: IOptions
    showScriptValidationMessage: boolean
    private pageViewFirstTime: boolean
    isSessionInited: boolean
    timeoutID: number
    utils: IUtils
    eventsStream: IEventsStream
    activity: IActivity
    JSAPI: object

    constructor({ showScriptValidationMessage }: { showScriptValidationMessage: boolean }) {
        ctxLog("version", process.env.VERSION)

        this.isSessionInited = false
        this.showScriptValidationMessage = showScriptValidationMessage

        this.pageViewFirstTime = false

        this.timeoutID = -1
        // const safeTrack = (...args) => safe(() => this.track(...args))
        this.JSAPI = {
            init: this.init.bind(this),
            setOptions: this.setOptions.bind(this),
            newPageView: this.newPageView.bind(this),
            identify: this.identify.bind(this),
            track: this.track.bind(this),
            setCustomParams: this.identify.bind(this),
            invalidateSession: this.invalidateSession.bind(this),
            off: this.off.bind(this),
            optOut: this.optOut.bind(this),
            getSessionURL: this.getSessionURL.bind(this),
            getVisitorID: this.getVisitorID.bind(this),
            debug: this.debug.bind(this),
            debug_force: this.debugForce.bind(this),
            log: this.log.bind(this),
        }
        const utils = new Utils()
        const options = new Options()
        const visitor = new Visitor({ utils, options })
        const account = new Account({ utils, options })
        const pageView = new PageView({ utils })
        const hooks = new Hooks()

        const globalEvents = new EventsListener({ utils })
        const xhrTransport = new XHRTransport({ options, globalEvents, visitor, pageView })
        const conditions = new Conditions({ globalEvents })
        const activity = new Activity({ utils })
        const eventsStream = new EventsStream({ conditions, utils, activity })
        const bundler = new EventsBundler({ eventsStream, transport: xhrTransport, globalEvents, activity, pageView, utils })
        const handlers = new Handlers({ account })
        const integrations = new Integrations({ globalEvents })

        integrations.Register("intercom", new Intercom({ maxAttemps: 30, utils, globalEvents, xhrTransport, visitor, JSAPI: this.JSAPI }))
        integrations.Register("livechat", new LiveChat({ maxAttemps: 30, utils, globalEvents, xhrTransport, options, visitor, JSAPI: this.JSAPI }))

        // const valueChangeHandler = new ValueChangeHandler({utils, globalEvents, eventsStream, options, aquireInterval: 100})
        const mouseMoveHandler = new MouseMoveHandler({ utils, eventsStream, aquireInterval: 125 })
        const touchMoveHandler = new TouchMove({ utils, eventsStream, aquireInterval: 125 })
        const windowScrollHandler = new WindowScrollHandler({ utils, eventsStream, aquireInterval: 500 })

        // VAlUE_CHANGE + VALUE_SET events
        const inputWatcher = new InputWatcher({ utils, options, eventsStream, globalEvents, aquireInterval: 500 })
        // FORM_SUBMIT event
        const formWatcher = new FormWatcher({ utils, options, eventsStream, globalEvents })

        handlers.Register(
            events.MUTATION,
            new DOMMutationHandler({ utils, eventsStream, globalEvents, inputWatcher, formWatcher, hooks, aquireInterval: 200, pageView, handlers }),
        )
        handlers.Register(events.LOCATION_CHANGE, new LocationChangeHandler({ utils, eventsStream, hooks, aquireInterval: 500 }))
        handlers.Register(events.MOUSE_MOVE, mouseMoveHandler)
        handlers.Register(events.TOUCH_MOVE, touchMoveHandler)
        handlers.Register(events.MOUSE_DOWN, new MouseDownHandler({ utils, eventsStream }))
        handlers.Register(events.MOUSE_UP, new MouseUpHandler({ utils, eventsStream }))
        handlers.Register(events.MOUSE_DOUBLE_CLICK, new MouseDoubleClickHandler({ utils, eventsStream }))
        handlers.Register(events.WINDOW_RESIZE, new WindowResizeHandler({ utils, eventsStream, aquireInterval: 500 }))
        handlers.Register(events.WINDOW_SCROLL, windowScrollHandler)
        handlers.Register(events.SELECTION, new SelectionHandler({ utils, eventsStream, globalEvents, aquireInterval: 300 }))
        handlers.Register(events.SCROLL, new ScrollHandler({ utils, eventsStream, globalEvents, aquireInterval: 300 }))
        handlers.Register(
            events.CSS_CHANGE,
            new CSSChangeHandler({
                utils,
                eventsStream,
                globalEvents,
                hooks,
                throttleMax: 1024,
                throttleInterval: 10000,
            }),
        )
        handlers.Register(events.MOUSE_CLICK, new MouseClickHandler({ utils, eventsStream, mouseMoveHandler, touchMoveHandler, windowScrollHandler }))
        handlers.Register(events.LOG, new Console({ utils, eventsStream, globalEvents, logsLimitPerPage: 256, hooks }))
        handlers.Register(events.PERFORMANCE, new PerformanceHandler({ eventsStream }))
        handlers.Register(events.TECHNOLOGY, new TechnologyScanHandler(eventsStream))
        handlers.Register(events.XHR_ERROR, new Network({ utils, eventsStream, globalEvents, logsLimitPerPage: 256, hooks }))
        handlers.Register(events.ANIMATION, new AnimationsHandler(hooks, utils, eventsStream))

        // handlers.Register(events.ELEMENT_BLUR, new ElementBlurHandler({utils, eventsStream}))
        // handlers.Register(events.ELEMENT_FOCUS, new ElementFocusHandler({utils, eventsStream}))
        handlers.Register(events.WINDOW_BLUR, new WindowBlurHandler({ utils, eventsStream }))
        handlers.Register(events.WINDOW_FOCUS, new WindowFocusHandler({ utils, eventsStream }))
        // handlers.Register(events.VALUE_CHANGE, valueChangeHandler) <- inputWatcher do the job

        globalEvents.on("bundler.stream_overflow", this.onCrash.bind(this))
        globalEvents.on("api.session.expired", this.onSessionExpired.bind(this))
        globalEvents.on("api.session.malformed_data", this.onMalformedData.bind(this))
        globalEvents.on("api.session.inited", this.onSessionInited.bind(this))
        globalEvents.on("api.response.turn_off", this.off.bind(this))
        globalEvents.on(`handlers.${events.MUTATION}.inited`, this.onPageContentReceived.bind(this))

        globalEvents.on("api.session.inited", this.updateLocalStorageVisitorData.bind(this))

        this.integrations = integrations
        this.conditions = conditions
        this.globalEvents = globalEvents
        this.eventsStream = eventsStream
        this.utils = utils
        this.bundler = bundler
        this.visitor = visitor
        this.account = account
        this.connection = xhrTransport
        this.pageView = pageView
        this.handlers = handlers
        this.options = options
        this.activity = activity

        this.identityData = null
        this.newPageViewData = null
        this.recordingRules = [
            { type: "event", name: defaultConditionEvents },
            // {type: "session", name: "browser.name", operator: "start", value: "Chrome"},
            // {type: "page_view", name: "location.referrer", operator: "contain", value: "/record"},
            // {type: "page_view", name: "utm.term", operator: "eq", value: ""},
            // {type: "event", name: "MouseClick", operator: "contain", value: ".submit-btn"},
        ]
        this.scanTechnologies = false
    }

    init(token = "", options = { keystrokes: false }) {
        const values = token.split(".")

        this.setOptions({ ...options, accountID: values[0], websiteID: values[1] })
    }

    createMessage(status: IScriptStatus) {
        const statusIcon = (value: boolean) =>
            value
                ? '<span style="margin-right: 6px!important;color: green!important;font-size: 13px!important;">✓</span>'
                : '<span style="margin-right: 6px!important;color: red!important;font-size: 13px!important;">✘</span>'
        const box = document.createElement("div")
        document?.querySelector("body")?.appendChild(box)
        box.outerHTML = `<div style="
        margin: 30px!important;
        position: fixed!important;
        z-index:9999999999!important;
        top:0!important;
        left:0!important;
        background-color:#ffe478!important;
        padding: 12px 16px!important;
        box-shadow: 4px 3px 11px  #000!important;
        font-size: 14px!important;
        border-radius: 4px!important;
        color:#222!important;
        font-family: sans-serif!important;
        "><strong>LiveSession</strong> script status:
        <ul style="padding:0; margin: 8px 0 0 0; list-style-type: none;"> 
        <li>${statusIcon(status.trackCodeValid)} Track ID (${status.trackId}) </li> 
        <li>${statusIcon(status.recordingEnabledValid)} Recording enabled ${status.error_message ? ` (${status.error_message})` : ""} </li>        
        </ul></div>`
    }

    displayTrackCodeMessage(message?: string): void {
        const id = `${this.options.AccountID()}.${this.options.WebsiteID()}`
        const ACCOUNT_NOT_EXISTS = "Account not exists"
        const INVALID_WEBSITE = "Invalid websiteID"
        const RECORDING_DISABLED = "Recording disabled"

        const trackCodeValid = message !== INVALID_WEBSITE && message !== ACCOUNT_NOT_EXISTS
        const scriptStatus: IScriptStatus = {
            trackId: id,
            trackCodeValid,
            recordingEnabledValid: message === undefined,
            error_message: message,
        }
        this.createMessage(scriptStatus)
    }

    invalidateSession() {
        this.visitor.InvalidateSession()
    }

    getVisitorID(cb) {
        this.afterSessionIsInited(() => {
            return cb(this.visitor.ID())
        }, null)
    }

    getSessionURL(cb) {
        this.afterSessionIsInited(() => {
            const isNewSession = this.pageView.IsNewSession()
            return cb(`${process.env.APP_URL}/app/sessions/${this.visitor.ID()}/${this.visitor.SessionID()}`, isNewSession)
        }, null)
    }

    identify(data) {
        // check is there any difference
        let diff = false
        forEach(data, (value, index) => {
            if (diff) return
            if (["name", "email", "params"].indexOf(index) < 0) {
                log.warn("identify object contains invalid property: `" + index + "`")
                return
            }
            if (index == "params") {
                forEach(value, (paramValue, paramName) => {
                    if (!this.identityData || !this.identityData.params || this.identityData.params[paramName] != paramValue) {
                        diff = true
                    }
                    return
                })
                return
            }
            if (!this.identityData || !this.identityData[index] || this.identityData[index] != value) {
                diff = true
                return
            }
        })
        if (!diff) {
            ctxLog("Skipping update_data:", "no difference")
            return
        }

        if (this.identityData) {
            this.identityData = {
                ...this.identityData,
                ...data,
            }
        } else {
            this.identityData = data
        }
        this.afterSessionIsInited(() => {
            this.updateData(data)
        }, "update-data")
    }

    track(event, properties) {
        event = typeof event == "string" ? event : ""
        properties = isObject(properties) ? properties : {}
        const [jsonData, err] = mapEventProps(properties)

        if (err) {
            console.error(err)
            return
        }

        this.eventsStream.Add(events.CUSTOM, {
            value: maxEventNameCleanup(event),
            json_data: jsonData,
        })
    }

    afterSessionIsInited(cb, id) {
        if (this.isSessionInited) return cb()
        this.globalEvents.once("api.session.inited", cb, id)
    }

    optOut() {
        Cookie.set("__ls_optout", "1", { expires: Number.MAX_SAFE_INTEGER })
        this.off()
    }

    debug(flag = true) {
        window["__ls_debug"] = flag
    }

    debugForce(flag = true) {
        window["__ls_debug_force"] = flag
    }

    log(...args) {
        const consoleHandler = this.handlers.Get(events.LOG)
        let argsArray = args
        if (consoleHandler) {
            let logLevel = "log"
            if (typeof argsArray === "string") {
            } else if (argsArray.length > 0) {
                if (consoleLogLevel[argsArray[0]]) {
                    logLevel = argsArray[0]
                    argsArray.shift()
                }
                consoleHandler.Console(logLevel, argsArray)
            }
        } else {
            console.warn("[LS] consoleHandler not found on log func")
        }
    }

    transport() {
        return this.connection
    }

    setOptions(options = { accountID: null, websiteID: null, keystrokes: null, rootHostname: null, storageOption: null }) {
        const { accountID, websiteID, keystrokes, rootHostname, storageOption } = options

        accountID && this.options.SetAccountID(accountID)
        websiteID && this.options.SetWebsiteID(websiteID)
        keystrokes !== null && this.options.Set("keystrokes", keystrokes)
        if (rootHostname) {
            this.options.Set("rootHostname", rootHostname)
        }

        if (storageOption) {
            this.options.Set("storageOption", storageOption)
        }
    }

    onCrash() {
        this.off()
        // this.visitor.CleanUpSession()
    }

    off() {
        this.eventsStream.Clear()
        this.activity?.Clear()
        this.handlers.CleanUp()
        ctxLog("Turned off.")
    }

    onSessionExpired() {
        this.newPageView()
    }

    onMalformedData() {
        this.invalidateSession()
        this.newPageView()
    }

    onPageContentReceived(rootID, pageContent) {
        this.onConfigReceived(pageContent)
    }

    onConfigReceived(pageContent) {
        if (this.timeoutID != -1) {
            clearTimeout(this.timeoutID)
            this.timeoutID = -1
        }

        const minDuration = this.account.settings.minTimeOnPage

        if (minDuration > 0 && !this.visitor.IsValid()) {
            this.timeoutID = setTimeout(() => {
                this.sendPageView(pageContent, minDuration)
            }, minDuration * 1000)
        } else {
            this.sendPageView(pageContent)
        }
    }

    onSessionInited({ isNew }) {
        if (this.scanTechnologies) {
            this.handlers.Get(events.TECHNOLOGY).Init()
        }
        if (this.identityData) this.updateData(this.identityData)
    }

    newPageView(options = {}) {
        let sendBundle = true

        if (!this.pageViewFirstTime && this.eventsStream.Length()) {
            sendBundle = false
        }

        this.newPageViewData = options
        this.pageView.SetPageViewOptions(options)
        this.isSessionInited = false

        if (sendBundle) {
            this.bundler.send()
            this.bundler.Reset()
            this.bundler.Stop()
            this.eventsStream.Clear()
            this.activity?.Clear()
            this.eventsStream.Disable()
        }

        this.utils.EventSeq.Reset()
        this.utils.Time.Reset() // reset timer
        this.handlers.CleanUp() // unregister handlers

        this.initialState = this.handlers.Init()
        this.conditions.Restart(options.conditions || this.recordingRules)
        this.conditions.Set([
            {
                type: "pageView",
                name: ["Init"],
            },
        ])

        this.transport().PageViewInit(
            (res) => {
                const { scan_technologies, settings, integrations } = res

                if (scan_technologies) {
                    this.scanTechnologies = true
                }

                this.account.Set(settings)
                this.integrations.SetEnabledList(integrations)
                this.integrations.Scan()
                // this.sendPageView(pageBody)

                const { recordingElementsMap, debugForce } = this.account.Settings()

                if (debugForce) {
                    this.debugForce(true)
                }

                if (recordingElementsMap && recordingElementsMap) {
                    this.utils.DOM.SetRecordingElementsMap(recordingElementsMap)
                }

                this.conditions.CheckPageViewData({
                    name: ["Init"],
                })
            },
            (err) => {
                const errorMessage = err.data || err
                ctxLog("init() error:", errorMessage)
                if (this.showScriptValidationMessage) this.displayTrackCodeMessage(errorMessage?.msg)
            },
        )

        this.globalEvents.once(
            "conditions.fulfilled",
            () => {
                this.utils.Time.Reset()
                this.utils.EventSeq.Reset()
                this.visitor.Load()
                this.eventsStream.Enable()
                this.initialState = this.handlers.Init()
                this.handlers.Get(events.LOCATION_CHANGE).Handler({ force: true })
                this.handlers.Get(events.MUTATION).Init()
                this.handlers.Get(events.CSS_CHANGE).Init()
                this.handlers.Get(events.PERFORMANCE).Init()
            },
            "new-page-view-conditions-fulfilled",
        )
    }

    sendPageView(pageBody, timeOffset) {
        const pv = this.pageView.GetPageViewInfo(this.newPageViewData)

        const getEvents = this.eventsStream.All()

        if (getEvents.length > 0) {
            // it's incremented because es.ActiveTimeJSON is a backend-only event but sequence comes from record-api
            // to keep this number consistent backend and tracking-code must increment this number.
            this.utils.EventSeq.Next()
        }

        const bundle = {
            events: getEvents,
        }

        this.transport().SendPageView(
            {
                ...pv,
                script_version: process.env.VERSION,
                script_timestamp_version: process.env.VERSION,
                script_tag_version: pJSON.version,
                page_view: {
                    ...pv.page_view,
                    page: {
                        ...pv.page_view.page,
                        body: JSON.stringify(pageBody),
                    },
                    initial_events: this.initialState,
                    script_timestamp_version: process.env.VERSION,
                    script_tag_version: pJSON.version,
                },
                visitor_side_storage: this.visitor.JSON(),
                time_offset: timeOffset,
                bundle,
            },
            this.pageViewInited.bind(this),
            (err) => {
                ctxLog("SendPageView() error:", err.data)
                if (this.showScriptValidationMessage) this.displayTrackCodeMessage(err?.message)
            },
        )
    }

    pageViewInited(res) {
        const {
            page_view_id,
            creation_timestamp,
            new_session,
            visitor_side_storage: { ls_sid, ls_vid, ls_sexp, ls_clsid },
        } = res

        if (!this.pageViewFirstTime) {
            this.pageViewFirstTime = true
        }

        this.visitor.Set(ls_vid, ls_sid, ls_sexp, ls_clsid)
        this.pageView.SetID(page_view_id)
        if (creation_timestamp) {
            this.pageView.SetCreationTimestamp(creation_timestamp)
        }
        this.pageView.SetSessionState(new_session)

        this.integrations.Scan()

        this.bundler.Start()

        this.isSessionInited = true

        this.globalEvents.call("api.session.inited", { isNew: new_session, settings: this.account.Settings() })
        const settings = this.account.Settings()
        if (settings.networkLogs) {
            enableLogSentry()
        }
        if (this.showScriptValidationMessage) this.displayTrackCodeMessage()
    }

    updateData({ name, email, params }) {
        let data = {}
        if (name) data.name = name
        if (email) data.email = email
        if (params) data.params = map(params, (value, key) => ({ name: key, value: value + "" }))

        this.eventsStream.Add(events.IDENTIFY, {
            json_data: data,
        })
    }

    // TODO: find better place - plugins in the future??
    updateLocalStorageVisitorData() {
        const PROTOCOL_DATA_KEY = "__ls_visitor_data"
        // TODO: add seq support!!!!
        localStorage.setItem(PROTOCOL_DATA_KEY, JSON.stringify({
            accountID: this.options.AccountID(),
            websiteID: this.options.WebsiteID(),
            pageViewID: this.pageView.ID(),
            pageViewCreationTimestamp: this.pageView.CreationTimestamp(),
            recordAPIURL: process.env.API_URL,
            vss: this.visitor.JSON(),
        }));
    }
}
